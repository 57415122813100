/**
 * Styling for DNN html blocks on sector pages
 */
.cl-branchev2 .row #dnn_TopPaneLeft .DnnModule ~ .DnnModule .DNNModuleContent.ModDNNHTMLC > * p a {
  display: block;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 16px);
}
