/* Bootstrap with custom overrides */
@import "variables";
@import "libs/bootstrap-custom/bootstrap";

/* Fonts and icons */
@import "libs/fontawesome/font-awesome";
@import "partials/cl-icons";

/* TODO: Remove gliderjs */
@import "libs/glider-js/glider";

@import "styles/libs/swiper/swiper";

/* Themes */
@import "partials/themes";
@import "partials/transitions";
@import "utilities/typography";
@import "utilities/theme-colors";
@import "utilities/colors";

@import "dnn/dnn-html-module";

/* prevent "broken image" on lazy loaded images */
img[data-src]:not(img[src]) {
  display: none;
}

// TODO: Clean up sass
.h1,
h1.field-title {
  @extend %h1;
}

.h2,
h2.field-title {
  @extend %h2;
}

.h3,
h3.field-title {
  @extend %h3;
}

.text-sans-serif {
  @extend %text-sans-serif;
}

.text-serif {
  @extend %text-serif;
}

.text-color-basic {
  @extend %text-color-basic;
}

.text-lg {
  font-size: 1rem;
}

.text-md {
  font-size: 0.875rem;
}

.text-sm {
  font-size: 0.75rem;
}

.text-xs {
  font-size: 0.625rem;
}

.hide-image-on-mobile {
  @include media-breakpoint-down(md) {
    picture,
    img {
      display: none;
    }
  }
}

#content.container-fluid > section {
  margin-left: -15px;
  margin-right: -15px;
}

// hide header element on symbol-page modals
body.symbol-page header {
  display: none;
}


body.no-scroll {
  overflow: hidden;
}

@include media-breakpoint-down(md) {
  body.no-scroll-md {
    overflow: hidden;
  }
}


#B2B-banner {
  .Toastify__toast-container--top-left {
    box-sizing: border-box;
    color: #fff;
    left: 50dvw;
    margin-left: -48dvw;
    padding: 0;
    position: fixed;
    width: 96dvw;
    z-index: 9999;
  }

  .Toastify__toast-theme--light {
    background-color: #FF8C00;
  }

  .Toastify__toast-body {
    color: white;
    font-weight: 600;
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 0;
  }
}
