@import "variables";

/** extends Font Awsome */
@font-face {
  font-display: swap;
  font-family: carellurvink-icon;
  font-style: normal;
  font-weight: normal;
  src:
    url("#{$static-assets-path}/fonts/carellurvink-icon.woff2") format("woff2"),
    url("#{$static-assets-path}/fonts/carellurvink-icon.woff") format("woff");
}

[class^="cl-"]::before,
[class*=" cl-"]::before {
  font-family: carellurvink-icon !important; // stylelint-disable-line font-family-no-missing-generic-family-keyword
  font-style: normal !important;
  font-weight: normal !important;
}

.cl-shield-line::before { content: "\e800"; }
.cl-cw::before { content: "\e801"; }
.cl-clock::before { content: "\e802"; }
.cl-archive::before { content: "\e803"; }
.cl-calculator::before { content: "\e804"; }
.cl-cart::before { content: "\e805"; }
.cl-check::before { content: "\e806"; }
.cl-crown::before { content: "\e807"; }
.cl-edit::before { content: "\e808"; }
.cl-knowledge-sharing::before { content: "\e809"; }
.cl-person::before { content: "\e80a"; }
.cl-shield-line-inner::before { content: "\e80b"; }
.cl-search::before { content: "\e80c"; }
.cl-shield::before { content: "\e80d"; }
.cl-trash::before { content: "\e80e"; }
.cl-truck::before { content: "\e80f"; }
.cl-add-to-list::before { content: "\e810"; }
.cl-quote::before { content: "\e811"; }
.cl-cards::before { content: "\e812"; }
.cl-long-arrow-right::before { content: "\e813"; }
.cl-long-arrow-up::before { content: "\e815"; }
.cl-down::before { content: "\e816"; }
.cl-restart::before { content: "\e817"; }
.cl-download::before { content: "\e818"; }
.cl-long-arrow-left::before { content: "\e819"; }
