// https://tailwindcss.com/docs/transition-property
.transition {
  transition-duration: 250ms;
  transition-property: height, background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.transition-delayed {
  transition-delay: 150ms;
}
