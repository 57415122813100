@import "variables";

:root {
  --theme-color: #8CC640;
  --theme-accent-color: #F4FFE6;
  --theme-color-1: #71A330;
  --theme-color-2: #8CC640;
  --theme-color-3: #A4D267;
  --theme-color-4: #F4FFE7;
  --input-bg: #{$input-bg};
  --input-border-color: #{$input-border-color};
  --success: #{$success};
  --warning: #{$warning};
  --danger: #{$danger};
  --text-white: #fff;
  --text-gray-lighten: rgba(255, 255, 255, 54%);
  --tab-background-color: #2363C0;
  --tab-active-color: #E26B24;
  --box-shadow-color: #{$box-shadow-color};
  --swiper-navigation-color: $gray-600;
  --swiper-navigation-size: 1.5rem;
  --swiper-pagination-bullet-height: 4px;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-inactive-opacity: 25%;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-size: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
  --swiper-pagination-bullet-width: 30px;
  --swiper-pagination-color: var(--theme-color-2);
  --swiper-pagination-bullet-inactive-color: var(--theme-color-2);
  --usp-color: #8CC640;
}

[data-theme="facility"] {
  --theme-color-1: #71A330;
  --theme-color-2: #8CC640;
  --theme-color-3: #A4D267;
  --theme-color-4: #F4FFE7;
  --theme-color: #8CC640;
  --theme-accent-color: #F4FFE7;
  --box-shadow-color: #{$box-shadow-color};
  --theme-header-background-1: #F4FFE7;
  --theme-header-background-2: transparent;
  --usp-color: #8CC640;
  --swiper-pagination-color: var(--theme-color-2);
  --swiper-pagination-bullet-inactive-color: var(--theme-color-2);
}

[data-theme="food"] {
  --theme-color-1: #0385B4;
  --theme-color-2: #0398CD;
  --theme-color-3: #03ABE6;
  --theme-color-4: #EAF9FF;
  --theme-color: #0398CD;
  --theme-accent-color: #EAF9FF;
  --box-shadow-color: #{$box-shadow-color};
  --theme-header-background-1: #EAF9FF;
  --theme-header-background-2: #eaf9ff;
  --usp-color: #8CC640;
  --swiper-pagination-color: var(--theme-color-2);
  --swiper-pagination-bullet-inactive-color: var(--theme-color-2);
}

[data-theme="medical"] {
  --theme-color-1: #3EB7AE;
  --theme-color-2: #5FC9C1;
  --theme-color-3: #85D6D0;
  --theme-color-4: #F0FBFA;
  --theme-color: #5FC9C1;
  --theme-accent-color: #F0FBFA;
  --box-shadow-color: #{$box-shadow-color};
  --theme-header-background-1: #F0FBFA;
  --theme-header-background-2: #F0FBFA;
  --usp-color: #8CC640;
  --swiper-pagination-color: var(--theme-color-2);
  --swiper-pagination-bullet-inactive-color: var(--theme-color-2);
}

[data-theme="industry"] {
  --theme-color-1: #E4B200;
  --theme-color-2: #FDC600;
  --theme-color-3: #FFD84B;
  --theme-color-4: #FFFCF3;
  --theme-color: #FDC600;
  --theme-accent-color: #FFFCF3;
  --box-shadow-color: #{$box-shadow-color};
  --theme-header-background-1: #FFFCF3;
  --theme-header-background-2: transparent;
  --usp-color: #8CC640;
  --swiper-theme-color: var(--theme-color-2);
  --swiper-pagination-bullet-inactive-color: var(--theme-color-2);
}
