// List Icons
// -------------------------

.#{$fa-css-prefix}-ul {
  list-style-type: none;
  margin-left: $fa-li-width;
  padding-left: 0;
  > li { position: relative; }
}
.#{$fa-css-prefix}-li {
  left: -$fa-li-width;
  position: absolute;
  text-align: center;
  top: divide(2em, 14);
  width: $fa-li-width;
  &.#{$fa-css-prefix}-lg {
    left: -$fa-li-width + divide(4em, 14);
  }
}
