/* stylelint-disable font-family-no-missing-generic-family-keyword */
// Base Class Definition
// -------------------------

.#{$fa-css-prefix} {
  display: inline-block;
  font-family: FontAwesome;
  font-size: inherit;
  line-height: #{$fa-line-height-base};
  text-rendering: auto;
}

/* Force icon font <element class"fa fa-...">Some text</element> */
[class^="#{$fa-css-prefix}"]:not([class^="fa cl-"]):before,
[class*=" #{$fa-css-prefix}"]:not([class*=" cl"]):before {
  font-family: FontAwesome !important;
  font-size: inherit;
  font-weight: normal;
  line-height: #{$fa-line-height-base};
}
