@import "variables";

@each $color, $value in $grays {
  .bg-gray-#{$color} {
    background-color: #{$value} !important;
  }
  .text-gray-#{$color} {
    color: #{$value} !important;
  }

  .border-#{$color} {
    border-color: $value !important;
  }
}
