// Icon Sizes
// -------------------------

/* makes the font 33% larger relative to the icon container */
.#{$fa-css-prefix}-lg,
.#{$fa-css-prefix}-lg:before {
  font-size: divide(4rem, 3) !important;
}
.#{$fa-css-prefix}-2x,
.#{$fa-css-prefix}-2x:before {
  font-size: 2em;
}

.#{$fa-css-prefix}-3x,
.#{$fa-css-prefix}-3x:before {
  font-size: 3em;
}
.#{$fa-css-prefix}-4x,
.#{$fa-css-prefix}-4x:before {
  font-size: 4em;
}

.#{$fa-css-prefix}-5x,
.#{$fa-css-prefix}-5x:before {
  font-size: 5em;
}
