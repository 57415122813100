/* FONT PATH
 * -------------------------- */

@font-face {
  font-display: swap;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  src:
    url("#{$fa-font-path}/fontawesome-webfont-woff2.woff2") format("woff2"),
    url("#{$fa-font-path}/fontawesome-webfont-woff.woff") format("woff");
}
